import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';

import { Text } from '../../common';
import FormInput from './FormInput';
import FormCheckboxGroup from './FormCheckboxGroup';
import FormInputClose from './FormInputClose';

import { styleVars, sortItems } from '../../lib/helpers';

const labelStyles = { color: 'grey6', fontWeight: 'bold', fontSize: [3] };

const FormFilter = ({ mobile = null, context }) => {
  const {
    filterSearch,
    filteredData,
    onUpdateFilter,
    filterValues,
    onClearFilter,
    onUpdateSearch,
    filterSelected,
    filtersLabel,
    title,
    inputAttrs,
    searchPlaceholder,
    searchLabel,
  } = useContext(context);

  const [showSearch, updateShowSearch] = useState(false),
    [showFilter, updateShowFilter] = useState(false),
    searchConditions = (mobile && showSearch) || !mobile,
    filterConditions = (mobile && showFilter) || !mobile,
    renderFilters = () =>
      Object.keys(filteredData).map((filter, index) => (
        <FormCheckboxGroup
          key={filter}
          idPrefix={mobile ? 'mobile-' : ''}
          className={index > 0 ? 'mt-6' : ''}
          label={filter}
          data={sortItems(
            Object.keys(filteredData[filter]).map((v) => ({ label: v, name: v, value: v })),
            { sortKeys: ['label'] }
          )}
          onChange={onUpdateFilter.bind(null, filter)}
          values={filterValues[filter]}
          title={title}
        />
      ));

  const mobileSearchHeader = mobile && (
    <>
      {showSearch ? (
        <Button
          variant="link"
          className="form-widget__filter-mobile-link p-0 text-left"
          onClick={updateShowSearch.bind(null, !showSearch)}
        >
          Close search
        </Button>
      ) : (
        <Button
          variant="link"
          className="form-widget__filter-mobile-link--decorated p-0 text-left"
          onClick={updateShowSearch.bind(null, !showSearch)}
        >
          {searchLabel}
        </Button>
      )}
    </>
  );

  const mobileFilterHeader = mobile && (
    <>
      {showFilter ? (
        <Button
          variant="link"
          className="form-widget__filter-mobile-link d-block mt-6 text-left"
          onClick={updateShowFilter.bind(null, !showFilter)}
        >
          Close Filter
        </Button>
      ) : (
        <Button
          variant="link"
          className={`form-widget__filter-mobile-link--decorated d-block ${
            showSearch ? 'mt-6' : 'mt-6'
          } mb-5 text-left`}
          onClick={updateShowFilter.bind(null, !showFilter)}
        >
          {filtersLabel}
        </Button>
      )}
    </>
  );

  return (
    <div className={`${!!mobile ? `d-${styleVars.mobileBreakpoint}-none flex-column form-widget__filter-mobile` : ''}`}>
      {mobileSearchHeader}
      {searchConditions && (
        <>
          <div>
            <Text {...labelStyles} className="mb-3">
              {searchLabel}
            </Text>
          </div>
          <div>
            <div className="form-widget__filter-mobile-input">
              <FormInput
                {...inputAttrs}
                onChange={onUpdateSearch}
                value={filterSearch}
                placeholder={searchPlaceholder}
                className="mt-3 form-label-approval__input"
                controlId="form-filter-search"
              />
              {filterSearch && <FormInputClose onClick={onUpdateSearch.bind(null, { target: { value: '' } })} />}
            </div>
          </div>
        </>
      )}
      {mobileFilterHeader}
      {filterConditions && (
        <>
          <div className="mt-6">
            {title && (
              <Text {...labelStyles} className="mb-3">
                {filtersLabel}
              </Text>
            )}
            {renderFilters()}
            {filterSelected && (
              <Button
                variant="link"
                className="form-widget__filter-mobile-link--decorated mt-6 p-0"
                onClick={onClearFilter}
              >
                Clear Filter
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FormFilter;
