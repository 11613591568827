import { useEffect, useState } from 'react';

const useLinkStart = ({ activePage, pages, linksInView }) => {
  const [linkStart, updateLinkStart] = useState(1),
    showEllipsis = pages > linksInView && pages - linkStart >= linksInView,
    links = Array.from({ length: showEllipsis ? linksInView - 1 : linksInView }, (n, i) => i + linkStart);

  useEffect(() => {
    const activePageBehind = activePage < linkStart,
      activePageAhead = activePage - linkStart >= links.length,
      pagesRemainingGreaterThanLinksInView = pages - activePage >= linksInView,
      pagesRemainingLessThanLinksInView = pages - activePage < linksInView;

    if (activePageBehind || (activePageAhead && pagesRemainingGreaterThanLinksInView)) {
      updateLinkStart(activePage);
      return;
    }

    if (activePageAhead && pagesRemainingLessThanLinksInView) {
      updateLinkStart(pages - links.length);
      return;
    }
  }, [activePage]);

  return { links, showEllipsis, linkStart };
};

export default useLinkStart;
