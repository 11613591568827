import React from 'react';
import classNames from 'classnames';

import { Text } from '../../common';
import Checkbox from './Checkbox';

const FormCheckboxGroup = ({ label, data, className, onChange, idPrefix = '', values, title }) => {
  const renderCheckboxes = data.map(({ label, name }) => (
    <Checkbox
      key={label}
      id={`${idPrefix}${label}`}
      className="mb-3"
      htmlFor={`${idPrefix}${label}`}
      name={name}
      label={label}
      onChange={onChange}
      checked={values[name]}
    />
  ));

  if (!data.length) return null;

  return (
    <>
      <Text
        className={classNames('mb-3 text-muted', title ? 'font-weight-medium' : 'font-weight-bold', className)}
        fontSize={[3]}
      >
        {label}:
      </Text>
      {renderCheckboxes}
    </>
  );
};

export default FormCheckboxGroup;
