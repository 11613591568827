import { useState, useEffect } from 'react'

export default (filters, onUpdate) => {
  const [rawParams, updateRawParams] = useState({})

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    for (let filter in filters) {
      const param = urlParams.getAll(filter.toLowerCase())

      updateRawParams(prevState => ({ ...prevState, [filter]: param }))
    }

  }, [typeof window !== 'undefined' ? window.location.search : null])

  useEffect(() => {
    if (Object.keys(rawParams).length) {
      const payload = {}

      for (let param in rawParams) {
        payload[param] = rawParams[param].reduce((acc, val) => {
          acc[val] = true

          return acc
        }, {})
      }

      onUpdate(payload)
    }
  }, [rawParams])
}