import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';

import { Icon } from '../../common';

const PaginationItem = ({ type = 'normal', active, disabled, className = '', text, onClick, ...rest }) => {
  const btnRef = useRef(null);

  const handleClick = (e) => {
    onClick(e)

    if (!btnRef) return

    // remove focus from button so focus moves to scroll location
    btnRef.current.blur()
  }

  const classList = className.split(' ').concat([
    'page-item',
    type === 'number' ? 'page-number' : 'pagination__end'
  ]);

  disabled && classList.push('disabled');
  active && classList.push('active');

  const template = (first, second, textFirst) => (
    <li className={classList.join(' ').trim()}>
      <Button
        className="page-link"
        disabled={disabled}
        onClick={handleClick}
        ref={btnRef}
        variant="link"
        {...rest}
      >
        {type === 'number' ?
          <>{text}</>
          :
          <>
            <span className={`pagination__end-sym ${textFirst && ' pagination__end-text'}`}>{first}</span>
            <span className={`pagination__end-sym ${type === 'next' && 'pagination__end--next'} ${!textFirst && ' pagination__end-text'}`}>{second}</span>
          </>
        }
      </Button>
    </li>
  )

  const first = [<Icon name="chevron-left-skip-first" />, 'First', false]
    , last = ['Last', <Icon name="chevron-right-skip-last" />, true]
    , prev = [<Icon name="chevron-left" />, 'Previous', false]
    , next = ['Next', <Icon name="chevron-right" />, true]
    , number = [text]
    , types = { first, last, prev, next, number }

  return template(...types[type])
}

export default PaginationItem
