import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Text, Link } from '../../common';
import ReactHtmlParser from 'react-html-parser';

import { getGoogleMapsUrl, transformHtmlWithLink } from '../../lib/helpers';

export default ({
  data: {
    title,
    fieldOfficeAddress,
    fieldOfficeServices,
    fieldOfficeHours,
    fieldOfficeNoticeAlert,
    fieldOfficeNoticeInfo,
    fieldPhoneNumber,
  },
}) => {
  const card = (
    <Card className="form-widget__card">
      <Card.Body>
        <Text className="mb-3" fontSize={[6]} fontWeight="bold">
          {title}
        </Text>
        <Row>
          <Col md="6">
            <Text fontSize={[4]} className="office-hub__card-text">
              {fieldOfficeAddress.addressLine1}
            </Text>
            {fieldOfficeAddress.addressLine2 && (
              <Text fontSize={[4]} className="office-hub__card-text">
                {fieldOfficeAddress.addressLine2}
              </Text>
            )}
            <Text
              fontSize={[4]}
              className="office-hub__card-text"
            >{`${fieldOfficeAddress.locality}, TX ${fieldOfficeAddress.postalCode}`}</Text>
            <Link href={getGoogleMapsUrl(fieldOfficeAddress)}>Get directions</Link>
            <Text className="office-hub__card-text mt-4" fontSize={[4]}>
              Phone: {fieldPhoneNumber}{' '}
            </Text>
            <Text as="div" className="office-hub__card-text" fontSize={[4]}>
              Open: {ReactHtmlParser(fieldOfficeHours.processed, { transform: transformHtmlWithLink })}
            </Text>
          </Col>
          <Col md="6" className="office-hub__card-header--bot">
            <Text fontSize={[4]} className="office-hub__card-text" fontWeight="bold">
              This office can help with:
            </Text>
            {fieldOfficeServices.map(({ entity: { name } }) => (
              <Text key={name} fontSize={[4]} className="office-hub__card-text">
                {name}
              </Text>
            ))}
          </Col>
        </Row>
        {(fieldOfficeNoticeAlert || fieldOfficeNoticeInfo) && (
          <Row>
            <Col xs="12" className="mt-4">
              {fieldOfficeNoticeAlert && (
                <Text className="office-hub__card-notice" color="darkRed" fontSize={[4]}>
                  <strong>Important Notice:</strong>
                  {` ${fieldOfficeNoticeAlert}`}
                </Text>
              )}
              {fieldOfficeNoticeInfo && (
                <Text className="office-hub__card-notice" color="blue" fontSize={[4]}>
                  <strong>Please Note:</strong>
                  {` ${fieldOfficeNoticeInfo}`}
                </Text>
              )}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );

  return card;
};
