import React, { useReducer, createContext } from 'react';

import useFilterParams from '../../hooks/useFilterParams';
import { mergeFilters, updateFilterParams, resetPagination, sortItems } from '../../lib/helpers';

import Widget from '../Widget/Widget';
import OfficeListCard from '../OfficeHub/OfficeListCard';

export const OfficeHubContext = createContext(null);

const { Provider: OfficeHubProvider } = OfficeHubContext,
  filterMapping = {
    fieldOfficeRegion: 'Region',
    fieldOffices: 'Status',
    fieldOfficeServices: 'Service',
    fieldOfficeCategory: 'Office Type',
  },
  initialFilters = Object.values(filterMapping).reduce((acc, v) => ({ ...acc, [v]: {} }), {}),
  initialState = { filters: initialFilters, filterSearch: '' };

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE_FILTER_SEARCH':
      return { ...state, filterSearch: payload };
    case 'UPDATE_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    default:
      return state;
  }
};

const OfficeHubWidget = ({ offices, currentPath }) => {
  const [state, dispatch] = useReducer(reducer, initialState),
    renderList = (office) => <OfficeListCard data={office} key={office.entityId} />,
    selectedTopics = Object.keys(state.filters).reduce((acc, key) => {
      const filter = Object.keys(state.filters[key]).reduce(
        (filterAcc, filterKey) => {
          if (state.filters[key][filterKey] === true) {
            filterAcc.selected.push(filterKey);
          }

          return filterAcc;
        },
        { topic: key, selected: [] }
      );

      if (filter.selected.length > 0) {
        acc.push(filter);
      }

      return acc;
    }, []),
    searchFilterConditions = (zips) => zips.search(state.filterSearch) != -1,
    generalConditions = (categories) => {
      const mappedCategories = Object.keys(filterMapping)
        .filter((key) => Object.keys(categories).includes(key))
        .map((key) => filterMapping[key]);
      const selectedGeneralTopics = selectedTopics.filter((selectedTopic) =>
        mappedCategories.includes(selectedTopic.topic)
      );

      if (!selectedGeneralTopics.length) return true;

      return selectedGeneralTopics.every((selectedTopic) => {
        const foundTopic = Object.keys(filterMapping).find((key) => filterMapping[key] === selectedTopic.topic);

        return selectedTopic.selected.includes(categories[foundTopic].entity.name);
      });
    },
    servicesCondition = (services) => {
      const mappedServices = services.map(({ entity: { name } }) => name);
      const selectedService = selectedTopics.find(
        (selectedTopic) => selectedTopic.topic === filterMapping.fieldOfficeServices
      );

      if (!selectedService) return true;

      return mappedServices.some((service) => selectedService.selected.includes(service));
    },
    onUpdateFilter = (type, { target: { checked, name } }) =>
      updateFilterParams(mergeFilters(state.filters, { type, key: name, value: checked })),
    onClearFilter = () => updateFilterParams(initialFilters),
    onUpdateSearch = ({ target: { value: payload } }) => {
      dispatch({ type: 'UPDATE_FILTER_SEARCH', payload });
      resetPagination();
    };

  useFilterParams(state.filters, (payload) => dispatch({ type: 'UPDATE_FILTERS', payload }));

  const filterConditions = ({
    fieldOfficeRegion,
    fieldOfficeCategory,
    fieldOfficeServices,
    fieldOffices,
    fieldZipCodesServed,
  }) => {
    const search = searchFilterConditions(fieldZipCodesServed),
      general = generalConditions({ fieldOfficeRegion, fieldOfficeCategory, fieldOffices }),
      services = servicesCondition(fieldOfficeServices);

    return search && general && services;
  };

  const data = sortItems(offices.filter(filterConditions), { sortKeys: ['title'] }),
    filterSelected = Object.values(state.filters).some((v) => Object.values(v).includes(true)),
    { filterSearch, filters: filterValues, sortType } = state;

  const filteredData = offices.reduce(
    (acc, { fieldOfficeRegion, fieldOfficeCategory, fieldOfficeServices, fieldOffices }) => {
      const filters = { fieldOfficeRegion, fieldOfficeCategory, fieldOfficeServices, fieldOffices };

      for (let filter in filters) {
        if (filter === 'fieldOfficeServices') {
          for (const {
            entity: { name },
          } of filters[filter]) {
            acc[filterMapping[filter]][name] = false;
          }
          continue;
        }

        const subFilter = filters[filter].entity.name;
        if (!acc[filterMapping[filter]][subFilter]) acc[filterMapping[filter]][subFilter] = false;
      }

      return acc;
    },
    { ...initialFilters }
  );

  return (
    <OfficeHubProvider
      value={{
        currentPath,
        data,
        renderList,
        filterSearch,
        filteredData,
        filterValues,
        onUpdateSearch,
        onUpdateFilter,
        onClearFilter,
        filterSelected,
        filtersLabel: 'Filter offices:',
        searchLabel: 'Filter by:',
        searchPlaceholder: 'Enter ZIP Code',
        sortType,
        inputAttrs: { type: 'number' },
      }}
    >
      <Widget context={OfficeHubContext} className={'office-hub__widget'} />
    </OfficeHubProvider>
  );
};

export default OfficeHubWidget;
