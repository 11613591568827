import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Text } from '../../common'

// TODO: style/add icon to empty state

const EmptyListState = ({ className = '', header, subheader }) => {
  return (
    <Row className={`${className}`}>
      <Col xs="12">
        <Text fontWeight="bold" fontSize={[4, 6]}>{ header }</Text>
      </Col>
      <Col className="mt-3">
        <Text fontSize={[1]}>{ subheader }</Text>  
      </Col>
    </Row>
  )
}


export default EmptyListState