import React, { useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Flex } from 'rebass';

const sortTypeAlpha = 'alphabetical',
  sortTypeDate = 'date',
  radioInputs = [
    { value: sortTypeAlpha, label: 'Alphabetical' },
    { value: sortTypeDate, label: 'Published date' },
  ];

const FormSort = ({ context }) => {
  const { sortType, onUpdateSort } = useContext(context);

  const renderRadioInputs = radioInputs.map(({ label, value }) => {
    const active = sortType === value;

    return (
      <Form.Check
        key={value}
        className={`${active ? 'active' : ''}`}
        onChange={onUpdateSort.bind(null, value)}
        checked={active}
        type="radio"
        label={label}
        custom
        id={label}
      />
    );
  });

  return (
    <>
      <Row className="mb-6">
        <Col>
          <Flex className="form-widget__sort">
            <span className="font-weight-medium">Sort by:</span>
            {renderRadioInputs}
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default FormSort;
