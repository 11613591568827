import React from "react";
import { Box } from "rebass";
import { graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";

import { Text, Heading } from "../../common";

import { transformHtmlWithLink } from "../../lib/helpers";

export const query = graphql`
  fragment introBlurb on Drupal_ParagraphIntro {
    fieldHeadingCopy
    fieldFormattedParagraph {
      processed
    }
  }
  fragment optionalIntroBlurb on Drupal_ParagraphOptionalIntro {
    fieldHeadingCopy
    fieldFormattedParagraph {
      processed
    }
  }
`;

const IntroBlurb = ({ entity: { entity: { fieldHeadingCopy, fieldFormattedParagraph } }, ...props }) => {
  return (
    <Box {...props}>
      <Heading as="h1" styledAs="hero" color="darkYellow">
        {fieldHeadingCopy}
      </Heading>
      {fieldFormattedParagraph?.processed &&
        <Text
          size="large"
          mT={3}
          mB={6}
          as="div"
          id="intro"
        >
          {ReactHtmlParser(fieldFormattedParagraph.processed, { transform: transformHtmlWithLink })}
        </Text>
      }
    </Box>
  );
};

export default IntroBlurb;
