import React, { useState, useEffect, useRef } from 'react';
import { Pagination } from 'react-bootstrap';

import useLinkStart from '../../hooks/useLinkStart';
import usePreviousHook from '../../hooks/usePrevious';
import useSiteUrl from '../../hooks/useSiteUrl';
import { useWindowSize } from '../../hooks/useWindowSize';

import { formatInternalUrl } from '../../lib/helpers';

import PaginationItem from './Item';

const isMobile = 576,
  linksMobile = 4,
  linksDesktop = 5;

const CustomPagination = ({
  data,
  data: { length: dataLength },
  renderData,
  perPage = 10,
  className = '',
  scrollContainerRef = 'self',
  currentPath,
}) => {
  const [activePage, updateActivePage] = useState(1),
    { width } = useWindowSize(),
    previousPageParam = usePreviousHook(activePage),
    siteUrl = useSiteUrl(),
    [linkLength, updateLinkLength] = useState(width < isMobile ? linksMobile : linksDesktop),
    pages = Math.ceil(dataLength / perPage),
    linksInView = pages >= linkLength ? linkLength : pages,
    showPrevious = activePage > 1,
    showNext = activePage < pages,
    showFirst = activePage > 1,
    showLast = activePage < pages,
    { showEllipsis, links } = useLinkStart({ activePage, pages, linksInView }),
    showFirstItemIndex = (activePage - 1) * perPage,
    paginationContainer = useRef(null),
    scrollToRef = scrollContainerRef === 'self' ? paginationContainer : scrollContainerRef;

  const updateAndNav = (page, e) => {
    const urlParams = new URLSearchParams(window.location.search);

    let paramString;

    e.preventDefault();

    if (page > 1) {
      urlParams.set('page', page);
    } else {
      urlParams.delete('page');
    }

    paramString = urlParams.toString();

    window.history.pushState(null, null, paramString.length ? `?${paramString}` : window.location.pathname);
    updateActivePage(page);

    if (!scrollToRef) return;

    scrollToRef.current.scrollIntoView();
  };

  const renderLinks = () => {
    const path = formatInternalUrl(`${siteUrl}${currentPath}`);
    const paramString = typeof window !== 'undefined' ? window.location.search : null;

    let href;

    return links.map((i) => {
      const urlParams = new URLSearchParams(paramString);

      let newParamString;

      if (i > 1) {
        urlParams.set('page', i);
      } else {
        urlParams.delete('page');
      }

      newParamString = urlParams.toString();
      href = newParamString.length ? `${path}?${newParamString}` : `${path}`;

      return (
        <PaginationItem
          active={activePage === i}
          href={href}
          key={i}
          onClick={updateAndNav.bind(null, i)}
          text={i}
          type="number"
        />
      );
    });
  };

  useEffect(() => {
    if (width <= isMobile && linkLength !== linksMobile) {
      updateLinkLength(linksMobile);
      return;
    }

    if (width > isMobile && linkLength !== linksDesktop) {
      updateLinkLength(linksDesktop);
    }
  }, [width]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = +urlParams.get('page');

    if (previousPageParam !== pageParam) {
      if (pageParam > pages || pageParam < 1 || !pageParam || isNaN(pageParam)) {
        let paramString;

        urlParams.delete('page');

        paramString = urlParams.toString();

        window.history.replaceState(null, null, paramString ? `?${paramString}` : window.location.pathname);
        updateActivePage(1);
      } else {
        updateActivePage(pageParam);
      }
    }
  }, [typeof window !== 'undefined' ? window.location.search : null]);

  if (pages < 1) return null;

  return (
    <div ref={paginationContainer} className={`${className}`}>
      <div>{data.slice(showFirstItemIndex, showFirstItemIndex + perPage).map(renderData)}</div>

      <div className="mt-8 d-flex flex-column">
        <Pagination>
          <PaginationItem type="first" disabled={!showFirst} onClick={(e) => showFirst && updateAndNav(1, e)} />
          <PaginationItem
            type="prev"
            disabled={!showPrevious}
            onClick={(e) => showPrevious && updateAndNav(activePage - 1, e)}
          />
          {renderLinks()}
          {showEllipsis && (
            <>
              <Pagination.Ellipsis className="pagination__ellipsis" />
              <Pagination.Item onClick={updateAndNav.bind(null, pages)} active={activePage === pages}>
                {pages}
              </Pagination.Item>
            </>
          )}
          <PaginationItem
            type="next"
            disabled={!showNext}
            onClick={(e) => showNext && updateAndNav(activePage + 1, e)}
          />

          <PaginationItem type="last" disabled={!showLast} onClick={(e) => showLast && updateAndNav(pages, e)} />
        </Pagination>
      </div>
    </div>
  );
};

export default CustomPagination;
