import React, { useContext } from 'react';
import FormSort from '../Form/FormSort';
import FormFilter from '../Form/FormFilter';
import Pagination from '../Pagination/Pagination';
import EmptyListState from '../Form/EmptyListState';
import { LeftContainer, MainContainer, PageContainer } from '../../common';
import { styleVars } from '../../lib/helpers/style';

const Widget = ({ context, className = '' }) => {
  const { sort = false, data, renderList, currentPath = null } = useContext(context);

  return (
    <div className={`form-widget ${className} pt-8 pb-13`}>
      {sort && (
        <MainContainer row>
          <LeftContainer />
          <PageContainer>
            <FormSort context={context} />
          </PageContainer>
        </MainContainer>
      )}

      <MainContainer row>
        <LeftContainer className={`d-none d-${styleVars.mobileBreakpoint}-block`}>
          <FormFilter context={context} />
        </LeftContainer>
        <PageContainer>
          <FormFilter mobile context={context} />
          {!!data.length ? (
            <Pagination
              currentPath={currentPath}
              scrollContainerRef="self"
              className="form-widget__list"
              data={data}
              renderData={renderList}
            />
          ) : (
            <EmptyListState
              header={"We can't find what you're looking for."}
              subheader={'Try broadening your selections.'}
            />
          )}
        </PageContainer>
      </MainContainer>
    </div>
  );
};

export default Widget;
