import * as React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'rebass';
import { styleVars } from '../../lib/helpers/style';
import Intro from '../../components/Intro/Intro';
import OfficeHubWidget from '../../components/OfficeHub/OfficeHubWidget';

import { MainContainer, LeftContainer, PageContainer } from '../../common';

export const query = graphql`
  fragment officeHubContent on Drupal_NodeOfficeHub {
    fieldH1Hero {
      entity {
        ... on Drupal_ParagraphIntro {
          fieldHeadingCopy
          fieldFormattedParagraph {
            value
            format
            processed
          }
        }
      }
    }
  }
  fragment officeContent on Drupal_NodeOfficePage {
    entityId
    title
    fieldFaxNumber
    fieldOffices {
      entity {
        name
      }
    }
    fieldOfficeHours {
      processed
    }
    fieldOfficeNoticeAlert
    fieldOfficeNoticeInfo
    fieldPhoneNumber
    fieldOfficeRegion {
      entity {
        ... on Drupal_TaxonomyTermOfficeRegion {
          name
        }
      }
    }
    fieldOfficeAddress {
      locality
      dependentLocality
      postalCode
      addressLine1
      addressLine2
      organization
    }
    fieldOfficeCategory {
      entity {
        ... on Drupal_TaxonomyTermOfficeType {
          name
        }
      }
    }
    fieldOfficeServices {
      entity {
        ... on Drupal_TaxonomyTermOfficeService {
          name
        }
      }
    }
    fieldZipCodesServed
  }
  query($nid: String!) {
    drupal {
      offices: nodeQuery(
        limit: 1000
        filter: {
          conditions: [
            { field: "type", value: "office_page", operator: EQUAL }
            { field: "status", value: "1", operator: EQUAL }
          ]
        }
      ) {
        entities {
          ...officeContent
        }
      }
      office: nodeById(id: $nid) {
        ...officeHubContent
      }
    }
  }
`;

export default ({
  data: {
    drupal: { office, offices },
  },
  pageContext: { pagePath },
}) => {
  const { fieldH1Hero } = office;

  return (
    <>
      <MainContainer row className={`pt-8 pt-${styleVars.mobileBreakpoint}-9`}>
        <LeftContainer />
        <PageContainer>{fieldH1Hero && <Intro entity={fieldH1Hero} />}</PageContainer>
      </MainContainer>
      <Box padding={7} />
      <OfficeHubWidget offices={offices.entities} currentPath={pagePath} />
    </>
  );
};
